import React from 'react';
import * as stellenPageStyles from './stellenPage.module.scss';
import Mech from '../../images/renault_various/renault-wartung.webp';

const StellenPage = () => {
  return (
    <div
      className={`p-2 container-fluid ${stellenPageStyles.stellenContainer}`}
    >
      <h1 className='text-center mt-4 pt-2'>
        <span className='yellow'>Offene Stellen</span>
      </h1>
      <div className='row headerContainer justify-content-center'>
        <div className='col-12 col-md-6 col-lg-4 align-content-center'>
          {/* <p className={`p-0 container-fluid ${stellenPageStyles.intro}`}>
            Unser Team braucht Verstärkung, wir suchen einen
            Automobil-Assistent/Fachmann/Mechatroniker.
          </p> */}
          <p>
            Schauen Sie gerne zu einem späteren Zeitpunkt wieder vorbei – wir
            freuen uns, wenn Sie in Zukunft Teil unseres Teams werden möchten!
          </p>
          {/*      <p className=''>
            Fragen zur Stelle:
            <a className='ms-1' href='mailto:info@bowman.ch'>
              info@bowman.ch
            </a>
          </p> */}
        </div>
        <div className='col-12 col-md-6 col-lg-4'>
          <img src={Mech} alt='Automech' />
        </div>
      </div>
    </div>
  );
};

export default StellenPage;
